<template>
  <v-tabs v-model="selectedTab" grow class="elevate-tab-headers">
    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.text }}
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>
    <v-tabs-items
      v-model="selectedTab"
      touchless
      class="elevation-0 transparent"
    >
      <v-tab-item v-for="item in tabs" :key="item.name">
        <current-day-tab v-if="item.name === 'current_day'"></current-day-tab>
        <current-month-tab
          v-else-if="item.name === 'current_month'"
        ></current-month-tab>
        <monthly-tab v-else-if="item.name === 'monthly'"></monthly-tab>
        <yearly-tab v-else-if="item.name === 'yearly'"></yearly-tab>
        <v-alert v-else :value="true" type="warning">Under construction</v-alert>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import CurrentDayTab from "../../common/dashboard/CurrentDayTab.vue";
import CurrentMonthTab from "../../common/dashboard/CurrentMonthTab.vue";
import MonthlyTab from "../../common/dashboard/MonthlyTab.vue";
import YearlyTab from "../../common/dashboard/YearlyTab.vue";

export default {
  components: { MonthlyTab, CurrentMonthTab, CurrentDayTab, YearlyTab },
  data: () => ({
    selectedTab: null,
    tabs: [
      { name: "current_day", text: "Today" },
      { name: "current_month", text: "This month" },
      // {name: 'weekly', text: 'Weekly'},
      { name: "monthly", text: "Monthly" },
      { name: "yearly", text: "Yearly" }
    ]
  })
};
</script>
