<template>
  <div>
    <div class="headline">
      Welcome {{ auth.user.first_name }} {{ auth.user.last_name }}
    </div>
    <div class="subtitle-1 light-grey">Quick overview of your data</div>
    <br />
    <business-dashboard-tabs></business-dashboard-tabs>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import BusinessDashboardTabs from "../dashboard/BusinessDashboardTabs.vue";

export default {
  components: {
    BusinessDashboardTabs
  },
  filters: {
    local_numbers
  },
  data: () => ({}),
  computed: {
    ...mapState("core/auth", {
      auth: state => state
    }),
    ...mapGetters("subscriptions", ["getActiveSubscription"])
  },
  created() {}
};
</script>
